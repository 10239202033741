import React, { useState, useEffect, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import service from './service';
import { InsuranceCompanyTableData, InsAddTableData } from './InsuranceCompanyTable';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { csvFileCheck } from '../../../utilities/validations';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_INSURANCE_COMPANIES_ADD, ROUTE_INSURANCE_COMPANIES_EDIT, ROUTE_USER_INSURANCE_COMPANIES_ADD, ROUTE_USER_INSURANCE_COMPANIES_EDIT } from '../../../utilities/staticConfigs';

import LoadingContext from '../../../container/loadingContext';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import Table from '../../commons/Table/Table';
import ExportIconButton from '../../commons/exportIconButton';
import BackArrowWithLabel from "../../commons/Back";
import CommonButton from "../../commons/Buttons";
import {permission_key_values_accountsetup, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import InsuranceCompaniesFilter from './InsuranceCompaniesFilter';


const InsuranceCompanies = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const practicePK = getStorage("practice");
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [header, setHeader] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [searchValueClose, setSearchValueClose] = useState(0);
    const [orderType, setOrderType] = useState('');
    const [initialOrderingPayerID, setInitialOrderingPayerID] = useState(true);
    const [initialOrderingName, setInitialOrderingName] = useState(true);
    const [orderingField, setOrderingField] = useState('payer_id'); /* show the triangle only in clicked header */
    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
   

    InsuranceCompanyTableData.tableHeadings[0].initialOrdering = initialOrderingPayerID;
    InsuranceCompanyTableData.tableHeadings[0].orderType = orderType;
    InsuranceCompanyTableData.tableHeadings[1].initialOrdering = initialOrderingName;
    InsuranceCompanyTableData.tableHeadings[1].orderType = orderType;
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);


    //Pagination start
    const [masterTotalPage, setMasterTotalPage] = useState(1);
    const [masterActivePage, setMasterActivePage] = useState(1);
    const [masterStartIndex, setMasterStartIndex] = useState(0);
    const [masterEndIndex, setMasterEndIndex] = useState(PAGING_END_INDEX);

    const [insuranceCompaniesList, setInsuranceCompaniesList] = useState([]);
    const [deleteInsuranceCompanyName, setDeleteInsuranceCompanyName] = useState("");
    const [deleteInsuranceCompanyId, setDeleteInsuranceCompanyId] = useState("");
    const [showImportErrorModalWindow, setShowImportErrorModalWindow] = useState(false);
    const [importErrorMessage, setImportErrorMessage] = useState('');
    const [showClearingHouseModal, setShowClearingHouseModal] = useState(false);
    const [gotoAdd, setGotoAdd] = useState(false);
    const [gotoEdit, setGotoEdit] = useState(false);
    const [insuranceID, setInsuranceID] = useState('');
    const [selectedID, setSelectedID] = useState('');
    const [practiceInsuranceMasterList, setPracticeInsuranceMasterList] = useState([]);
    const [searchFilterVariables, setSearchFilterVariables] = useState({
        "name_contains": "", "apms_payer_id": "", "payer_id": "", "exact_match_only": false
    });
    const [addFromClearingHouseSearchFlag, setAddFromClearingHouseSearchFlag] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        return newWidth;
    }, [window.innerWidth]);

    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES)
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }

    function resetForm() {
        setHeader("");
        setSearchFilterVariables({
            "name_contains": "", "apms_payer_id": "", "payer_id": "", "exact_match_only": false
        })
    }
    function addNew() {
        if (checkPermission(permission_key_values_accountsetup.account_setup_insurance_management_modify)) {
            setHeader(i18n.t("dictionariesPages.dictInsuranceCompanies.addHeader"));
            history.push(ROUTE_USER_INSURANCE_COMPANIES_ADD);
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }

    }

    function onHide() {
        setSearchFilterVariables({
            "name_contains": "", "apms_payer_id": "", "payer_id": "", "exact_match_only": false
        })
    }
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setEndIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

    }
    function onMasterPagePrevious() {
        let previousPage = masterStartIndex + 1 - PAGING_END_INDEX;
        setMasterActivePage(previousPage);
        if (masterStartIndex !== 0) {
            setMasterStartIndex(masterStartIndex - PAGING_END_INDEX);
            setMasterEndIndex(masterEndIndex - PAGING_END_INDEX);
        }
        getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onMasterPageUp(e) {
        let page = Number(e.target.id)
        setMasterActivePage(page);
        getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onMasterPageNext() {
        let nextPage = masterStartIndex + 1 + PAGING_END_INDEX;
        if (masterEndIndex === masterTotalPage || masterTotalPage <= PAGING_END_INDEX) {
            setMasterActivePage(nextPage);
            setMasterStartIndex(masterStartIndex);
            setMasterEndIndex(masterEndIndex);

        } else {
            setMasterActivePage(nextPage);
            setMasterStartIndex(masterStartIndex + PAGING_END_INDEX);
            setMasterEndIndex(masterEndIndex + PAGING_END_INDEX);

        }
        getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

    }
    function getInsuranceCompaniesData(pageSize, page, fieldName, sorting) {
        setShowLoadingBar(true);
        let result = null;
        let fieldOrderType = '';
        if (sorting) {
			if (!orderType) fieldOrderType = '-';
		} else {
			fieldOrderType = orderType;
		} 
        let practicePk=isAdminModule=='true' ? null : practicePK;
        result = service.ListInsuranceCompanies(pageSize, page, isAdminModule,practicePk, null, null, searchValue, fieldName, fieldOrderType);
        result.then(response => {
            if (response.data.results !== undefined) {
                let dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
                setTotalPage(dataTotalPage);
            }
            setShowLoadingBar(false);
            const rowArray = commonTableBody(response.data.results, InsuranceCompanyTableData.tableBodyData[0])
            InsuranceCompanyTableData.tableBodyData = rowArray;
            setInsuranceCompaniesList(response.data.results);
            if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
                onEditInsuranceCompany(props.location.state.selectedID);
            }
        });
    }
    useEffect(() => {
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, activePage);
    }, [searchValueClose])

    // insuranceCompaniesList

    function onHandleChange(e) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value;
            let trimmedValue = '';
            trimmedValue = value;
            if (name === "searchValue") setSearchValue(trimmedValue);
        }
    }
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onEditInsuranceCompany(InsuranceCompanyId) {
        setSelectedID(InsuranceCompanyId);
        setGotoEdit(true);
    }
    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        data = service.DeleteInsuranceCompany(deleteInsuranceCompanyId,isAdminModule == 'true' ? '' : practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteInsuranceCompanyName('');
            setDeleteInsuranceCompanyId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            if (totalPage <= activePage) {
                getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, 'last');
            } else {
                getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, activePage);
            }
        });
    }
    function onDeleteInsuranceCompany(InsuranceCompanyId) {
        insuranceCompaniesList.map((item) => {
            if (item.id === parseInt(InsuranceCompanyId)) {
                setDeleteInsuranceCompanyName(item.name);
                setDeleteInsuranceCompanyId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }
    function onDeleteHide() {
        setDeleteInsuranceCompanyId('');
        setDeleteInsuranceCompanyName('');
        setShowDeleteModalWindow(false);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchInsurancesCompaines(e);
        }
    }

    function onSearchInsurancesCompaines(e, fieldName) {
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setInitialOrderingName(true);
            setInitialOrderingPayerID(true);
            setOrderType('');
        }
        setTotalPage(0)
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, 1, fieldName, true);
    }

    function onSortingInsurancesCompaines(e, fieldName) {
        e.preventDefault();
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setInitialOrderingName(true);
            setInitialOrderingPayerID(true);
            setOrderType('');
        }
        setTotalPage(0)
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, activePage, fieldName, true);
    }

    const tableSorting = (e, fieldName) => {
        if (fieldName === 'payer_id') {
            setInitialOrderingName(true);
            setInitialOrderingPayerID(false);
        }
        else if (fieldName === 'name') {
            setInitialOrderingPayerID(true);
            setInitialOrderingName(false);
        }
        setOrderingField(fieldName);
        if (!orderType) setOrderType('-');
        else setOrderType('');
        onSortingInsurancesCompaines(e, fieldName);
    }
    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').value = null;
        document.getElementById('file').click();
    }

    function onHandleCSVChange(e) {
        setShowLoadingBar(true);
        const value =
            e.target.type === "file" ? e.target.files[0] : e.target.value;

        if (e.target.type === "file") {
            if (e.target.files[0] && csvFileCheck(e.target.files[0].name)) {
                const data = new FormData();
                data.append('file', value);
                let result = service.ImportCSVData(data);
                result.then(response => {
                    setShowLoadingBar(false);
         if (response.status == 200) {
                    if (response.data.response_data.message !== undefined) {
                         if (response.data.response_data.message === 'data_imported') {
                            if (response.data.response_data.existing_name && response.data.response_data.existing_name.length > 0) {
                                setImportErrorMessage(i18n.t('errorMessages.record_not_imported') + ' \n  \n' + response.data.response_data.existing_name.join(',\n'));
                                showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + response.data.response_data.existing_name.join(',\n'));
                            } else {
                                showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
                            }
                             getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, 1, orderingField);
                        }
                    }

                    } else if (response.status === 400) {
                        if (response.data.message === 'Invalid_payment_source') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.Invalid_payment_source'));
                        } else if (response.data.message === 'invalid_headings') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
                        } else
                        {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
                        }
                    } else {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
                    }
                })
            
            } else {
                setShowLoadingBar(false);
                showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
            }
        }
    }

    function onImportErrorOK() {
        setShowImportErrorModalWindow(false);
    }

    function showClearinHouseModalWindow() {
        if (checkPermission(permission_key_values_accountsetup.account_setup_insurance_management_modify)) {
            setHeader(i18n.t('dictionariesPages.dictInsuranceCompanies_Add.addfromClearinghouseList'));
            getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, 1);
            setSearchFilterVariables(prevState => ({
                ...prevState,
                "name_contains" : '',
                "apms_payer_id" : '',
                "payer_id" : ''
            }))
            setShowClearingHouseModal(true);
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }


    function onHandleSearchFilters(e) {
        let name = e.target.name;
        let value = "";
        if (e.target.type === "checkbox")
            value = e.target.checked;
        else value = e.target.value;
        setSearchFilterVariables({
            ...searchFilterVariables, [name]: value
        });
        setAddFromClearingHouseSearchFlag(true);
    }

    useEffect(() => {
        if (addFromClearingHouseSearchFlag) {
            const timeoutId = setTimeout(() => {
                getPracticeInsuranceMasterData(DEFAULT_PAGING_SIZE, 1, orderingField, true);
            }, 500); // delay in milliseconds

            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [JSON.stringify(searchFilterVariables), addFromClearingHouseSearchFlag]);

    function getPracticeInsuranceMasterData(pageSize, page, orderBy, resetPagination) {
        setPracticeInsuranceMasterList([]);
        setShowLoadingBar(true);
        let result = service.GetPracticeInsuranceMasterData(pageSize, page, orderBy, searchFilterVariables, practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            let dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
            setMasterTotalPage(dataTotalPage);
            setMasterActivePage(page);

            if (resetPagination) {
                setMasterEndIndex(dataTotalPage > 10 ? PAGING_END_INDEX : dataTotalPage);
                setMasterStartIndex(0);
            }

            const rowArray = commonTableBody(response.data.results, InsAddTableData.tableBodyData[0])
            InsAddTableData.tableBodyData = rowArray;

            setPracticeInsuranceMasterList(response.data.results)
        });
        setAddFromClearingHouseSearchFlag(false);
    }

    function onAddMasterInsurance(insuranceCompanyId) {
        setInsuranceID(insuranceCompanyId);
        setGotoAdd(true);
    }

    if (gotoAdd) {
        if(isAdminModule === 'true')
            return <Redirect to={{ pathname: ROUTE_INSURANCE_COMPANIES_ADD, state: { insuranceID: insuranceID } }} />;
        else return <Redirect to={{ pathname: ROUTE_USER_INSURANCE_COMPANIES_ADD, state: { insuranceID: insuranceID } }} />;
    }
    if (gotoEdit) {
        if (isAdminModule === 'true')
            return <Redirect to={{ pathname: ROUTE_INSURANCE_COMPANIES_EDIT, state: { selectedID: selectedID } }} />;
        else 
            return <Redirect to={{ pathname: ROUTE_USER_INSURANCE_COMPANIES_EDIT, state: { selectedID: selectedID } }} />;
    }


    function dropDownFunction(id, name) {
        const superAdminPermissionCheck = checkPermission(super_admin_privileges.super_admin_full_privilege);
        const aimaStaffPermissionCheck = checkPermission(super_admin_permission_key_value.dictionary_add);
        const customerPermissionCheck = checkPermission(permission_key_values_accountsetup.account_setup_insurance_management_modify);
        const permissions = isAdminModule === 'true' ?  (aimaStaffPermissionCheck  || superAdminPermissionCheck) : customerPermissionCheck ;

        if (name === 'Edit' && permissions) {
            onEditInsuranceCompany(id);
        }
        else if (name === 'Delete' && permissions) {
            onDeleteInsuranceCompany(id);
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }

    function sortingFunction(e, name) {
        if (name === i18n.t("dictionariesPages.dictInsuranceCompanies.payerID")) {
            tableSorting(e, 'payer_id');
        } else {
            tableSorting(e, 'name')
        }
    }

    function onRowClick(e, id) {
        onAddMasterInsurance(id);
    }


    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line no-undef
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        service.exportInsuranceCompany(type, practicePK, DEFAULT_PAGING_SIZE, 1, orderingField, searchFilterVariables)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                    throw Error(result.statusText);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };


    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">

                <div className="box box-content-white margin-right15">
                    <div className="row">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                {isAdminModule === 'true' &&
                                    <div className="link dictionaries-back" onClick={backToDictionaryPage}>
                                        <BackArrowWithLabel label={i18n.t("dictionariesPages.gotoDictionaries")} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput type="text" id="searchValue" name="searchValue" value={searchValue} onValueChange={onHandleChange} label={i18n.t("dictionariesPages.dictInsuranceCompanies.payerIDOrName")} onKeyDown={handleKeyDown} labelClassName={"margin-bottom0"} />

                                    {(searchValue.length !== 0) && <IconButton
                                        className="insuranceIconBtn_close"
                                        onClick={() => {
                                            setSearchValue('');
                                            setActivePage(1)
                                            setSearchValueClose(searchValueClose + 1)
                                        }} >
                                        <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                                    </IconButton>
                                    }
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <button type="button" onClick={(e) => onSearchInsurancesCompaines(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.dictInsuranceCompanies.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box  box-content-white min-h-full margin-top10 margin-right15">
                    <div className=" ">
                        <div className="alignRight col-8 pr-0">
                            {isAdminModule !== 'true' &&
                                <div className='padding-left15'>
                                    <div>
                                        <button type='button' onClick={addNew} className='btn-primary-blue btn ml-4 margin-bottom10 margin-top10 alignRight'>{i18n.t('dictionariesPages.dictInsuranceCompanies_Add.create_new_one_manually')}</button>
                                        <button type='button' onClick={showClearinHouseModalWindow} className='btn-primary-blue margin-bottom10 margin-top10 btn alignRight' >{i18n.t('dictionariesPages.dictInsuranceCompanies_Add.createFromClearingHouseConnection')}</button>
                                    </div>
                                    <div className='margin-bottom10 margin-top10 alignRight mr-4'>
                                        <ExportIconButton
                                            disabled= {insuranceCompaniesList == undefined || insuranceCompaniesList?.length <= 0 ? true : false}                                            onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                            onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                                    </div>
                                </div>
                            }
                        </div>
                        {isAdminModule === 'true' && <div className="pr-0 alignRight margin-bottom7 margin-right2">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t('commons.importCSV')}
                                </Tooltip>}>
                                    <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                            </OverlayTrigger>
                            <input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
                        </div>}
                        <div className={""} style={{ overflowX: "auto", width: "100%"}}>
                            <Table tableObject={InsuranceCompanyTableData} list={practiceInsuranceMasterList} dropDownFunction={dropDownFunction} sortingFunction={sortingFunction} />
                        </div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    </div>
                </div>
            </div>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictInsuranceCompanies.deletConfirmation") + "'" + deleteInsuranceCompanyName + "'?"}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={showImportErrorModalWindow} header={i18n.t("commons.importErrorHeader")} alertOK={onImportErrorOK} type="alert"  setShowModalWindow={setShowImportErrorModalWindow}>
                {importErrorMessage}
            </CustomizedSmallDialogs>
            <CustomizedDialogs modalId={'longWidth'} showModal={showClearingHouseModal} header={header} setShowModalWindow={setShowClearingHouseModal} resetForm={resetForm} onHide={onHide}>
                <InsuranceCompaniesFilter searchFilterVariables={searchFilterVariables} onHandleSearchFilters={onHandleSearchFilters} InsAddTableData={InsAddTableData}
                onRowClick={onRowClick} masterTotalPage={masterTotalPage} masterActivePage={masterActivePage} masterStartIndex={masterStartIndex} masterEndIndex={masterEndIndex} 
                onMasterPagePrevious={onMasterPagePrevious} onMasterPageUp={onMasterPageUp} onMasterPageNext={onMasterPageNext}/>
            </CustomizedDialogs>
        </React.Fragment>
    )
}

export default InsuranceCompanies
