import React, { useState, useEffect, useContext } from "react";
import { useLocation  } from 'react-router-dom';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import service from "./service";
import {
  ICD10Table,
  ICD10AdminTable,
  TableBodyData,
  TableBodyAdminData,
  ICD10AddTableObject,
} from "./ICD10Table";
import moment from "moment";
import i18n from "../../../utilities/i18n";
import { csvFileCheck } from "../../../utilities/validations";
import { getStorage } from "../../../utilities/browserStorage";
import { checkPermission, commonTableBody } from "../../../utilities/commonUtilities";
import {
  ADD_SUCCESS,
  DELETE_SUCCESS,
} from "../../../utilities/labelConfigs";
import {
  ROUTE_SUPER_DICTIONARIES,
  PAGING_END_INDEX,
  DEFAULT_PAGING_SIZE,
  ROUTE_CODE_MANAGEMENT,
  ROUTE_ICD10,ACTIVE_TYPES,
} from "../../../utilities/staticConfigs";

import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";

import LoadingContext from "../../../container/loadingContext";

import Notify from "../../commons/notify";
import Table from "../../commons/Table/Table";
import Pagination from "../../commons/pagination";
import TextInput from "../../commons/input/input";
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";

import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { IDC10Edit } from "./dictICDEdit";
import ExportIconButton from "../../commons/exportIconButton";
import SelectInput from '../../commons/input/SelectInput'; 

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { permission_key_values_accountsetup,super_admin_permission_key_value,super_admin_privileges } from "../../../utilities/permissions";

const Icd10 = (props) => {
  const isAdminModule = getStorage("isAdminModule");
  const location = useLocation();
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [ICD10List, setICD10List] = useState([]);
  const [showMasterICD10Modal, setShowMasterICD10Modal] = useState(false);
  const [masterICD10List, setMasterICD10List] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchICD10, setSearchICD10] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValueClose, setSearchValueClose] = useState(0);
  const [header, setHeader] = useState("");
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [editICD10Id, setEditICD10Id] = useState("");
  const [activeType, setActiveType] = useState('2'); 

  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
  const [deleteICD10, setDeleteICD10] = useState("");
  const [deleteICD10Id, setDeleteICD10Id] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderingField, setOrderingField] =
    useState("name"); /* show the triangle only in clicked header */
  const [initialOrdering, setInitialOrdering] = useState(true);

  const [showAddManuallyWindow, setShowAddManuallyWindow] = useState(false);
  const [addManualActiveStatus, setAddManualActiveStatus] = useState(false);
  const [editActiveStatus, setEditActiveStatus] = useState(false);

  if (isAdminModule === "true") {
    ICD10AdminTable.tableHeadings[0].initialOrdering = initialOrdering;
    ICD10AdminTable.tableHeadings[0].orderType = orderType;
  } else {
    ICD10Table.tableHeadings[0].initialOrdering = initialOrdering;
    ICD10Table.tableHeadings[0].orderType = orderType;
  }

  //start ==== Alert message properties
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

  //permission state
  const [permission, setPermission] = useState(false);
  useEffect(() => {

    if(location.pathname === ROUTE_ICD10){
      setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
    } else {
      setPermission(checkPermission(permission_key_values_accountsetup.account_setup_code_management_add,
       permission_key_values_accountsetup.account_setup_code_management_modify,
       super_admin_privileges.super_admin_full_privilege));
  }
  },[])


  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getICD10List(DEFAULT_PAGING_SIZE, previousPage, orderingField);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getICD10List(DEFAULT_PAGING_SIZE, page, orderingField);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getICD10List(DEFAULT_PAGING_SIZE, nextPage, orderingField);
  }
  //Pagination ends

 

  //Master Pagination start
  const [masterTotalPage, setMasterTotalPage] = useState(1);
  const [masterActivePage, setMasterActivePage] = useState(1);
  const [masterStartIndex, setMasterStartIndex] = useState(0);
  const [masterEndIndex, setMasterEndIndex] = useState(PAGING_END_INDEX);

  function onMasterPagePrevious() {
    let previousPage = masterStartIndex + 1 - PAGING_END_INDEX;
    setMasterActivePage(previousPage);

    if (masterStartIndex !== 0) {
      setMasterStartIndex(masterStartIndex - PAGING_END_INDEX);
      setMasterEndIndex(masterEndIndex - PAGING_END_INDEX);
    }
    getMasterICD10List(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onMasterPageUp(e) {
    let page = Number(e.target.id);
    setMasterActivePage(page);
    getMasterICD10List(DEFAULT_PAGING_SIZE, page);
  }

  function onMasterPageNext() {
    let nextPage = masterStartIndex + 1 + PAGING_END_INDEX;
    if (
      masterEndIndex === masterTotalPage ||
      masterTotalPage <= PAGING_END_INDEX
    ) {
      setMasterActivePage(nextPage);
      setMasterStartIndex(masterStartIndex);
      setMasterStartIndex(masterEndIndex);
    } else {
      setMasterActivePage(nextPage);
      setMasterStartIndex(masterStartIndex + PAGING_END_INDEX);
      setMasterEndIndex(masterEndIndex + PAGING_END_INDEX);
    }
    getMasterICD10List(DEFAULT_PAGING_SIZE, nextPage);
  }
  //Master Pagination ends

  useEffect(() => {
    getICD10List(DEFAULT_PAGING_SIZE, 1);
  }, [activeType])

 /** changed to async/await for more readability */
  async function getICD10List(pageSize, page, fieldName, sorting) {
    setShowLoadingBar(true);
    let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
    let fieldOrderType = "";
    if (sorting) {
      if (!orderType) fieldOrderType = "-";
    } else {
      fieldOrderType = orderType;
    }
    const module = isAdminModule === "true" ? null : practicePK;
    
    try {
      const response = await service.ListICD10(pageSize, page, isAdminModule, module, false, searchValue, null, fieldName, fieldOrderType,status);
      if (response && response.data) {
        response.data.results.forEach((item) => {
          if (item.effective_date && !(item.effective_date instanceof Date)) {
            item.effective_date = moment(item.effective_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
          }
          if (item.deletion_date && !(item.deletion_date instanceof Date)) {
            item.deletion_date = moment(item.deletion_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
          }
        });
        if (response?.data?.results) {
          const dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
          setTotalPage(dataTotalPage);

          if (dataTotalPage < activePage) {
            const deleteStartIndex = Math.floor(dataTotalPage / PAGING_END_INDEX);
            setStartIndex(deleteStartIndex);
            setEndIndex(dataTotalPage);
          }

          const newData = response.data.results.map((result) => {
            return ICD10AdminTable.tableBodyData[0].map((col) => {
              const value = getValueForColumn(col, result);
              return { ...col, value, id: result.id };
            });
          });
          const tableData = newData.length > 0 ? newData : (isAdminModule ? TableBodyAdminData : TableBodyData);

          if (isAdminModule === "true") {
            ICD10AdminTable.tableBodyData = tableData;
          } else {
            ICD10Table.tableBodyData = tableData;
          }

          setICD10List(response.data.results);
        }
      }
      setShowLoadingBar(false);
    } catch (error) {
      setShowLoadingBar(false);
    }
  }
  
  function getValueForColumn(column, result) {
    const { name } = column;
    
    if (isAdminModule === "true") {
      return result[name];
    }
    
    switch (name) {
      case "name":
        return result.name;
      case "short_description":
        return result.short_description;
      case "gender_name":
        return result.gender_name;
      case "low_age":
        return result.low_age;
      case "high_age":
        return result.high_age;
      case "effective_date":
        return result.effective_date;
      case "deletion_date":
        return result.deletion_date;
      default:
        return "";
    }
  }
  

 
  /**
   * changed to async/await  with try , catch block
   * @param {*} pageSize 
   * @param {*} page 
   * @param {*} search 
   * @param {*} fromSearch 
   */
  async function getMasterICD10List(pageSize, page, search, fromSearch) {
    setShowLoadingBar(true);
    let searchValue = null;
    if (fromSearch && search && search.length > 1) {
      searchValue = search;
    } else if (!fromSearch && searchICD10 && searchICD10.length > 1) {
      searchValue = searchICD10;
    }

    try {
      const response = await service.ListICD10(
        pageSize,
        page,
        isAdminModule,
        practicePK,
        true,
        searchValue,
        "dropdown"
      );

      setShowLoadingBar(false);
      if (response.data.results !== undefined) {
        // Add the "checkbox" property with a default value of false in each object of the results array
        const resultsWithCheckbox = response.data.results.map(item => ({
          ...item,
          checkbox: false,
        }));

        const totalPage = Math.ceil(response.data.count / response.data.page_size);
        setMasterTotalPage(totalPage);
        const rowArray = commonTableBody(resultsWithCheckbox, ICD10AddTableObject.tableBodyData[0]);

        rowArray.forEach((row) => {
          const checkbox = row.find((item) => item.name === "checkbox");
          if (checkbox && selectedIds.includes(checkbox.id)) {
            checkbox.value = true;
          }
        });
        ICD10AddTableObject.tableBodyData = rowArray;
        setMasterICD10List(response.data.results);
      }
    } catch (error) {
      setShowLoadingBar(false);
    }
  }

useEffect(()=> {
  const rowArray = ICD10AddTableObject.tableBodyData;
  rowArray.forEach((row) => {
    const checkbox = row.find((item) => item.name === "checkbox");
    if (checkbox && selectedIds.includes(checkbox.id)) {
      checkbox.value = true;
    } else {
      checkbox.value = false;
    }
  });
  ICD10AddTableObject.tableBodyData = rowArray;
  setMasterICD10List([...rowArray]);

},[selectedIds])


  useEffect(() => {
    getICD10List(DEFAULT_PAGING_SIZE, activePage);
  }, [searchValueClose]);

  function backToDictionaryPage() {
    props.history.push(ROUTE_SUPER_DICTIONARIES);
  }

  function showNotifyWindow(action, type, desc, ages = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, ages);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onHandleCsvImport(e) {
    e.preventDefault();
    document.getElementById("file").value = null;
    document.getElementById("file").click();
  }

  function onHandleCSVChange(e) {
    setShowLoadingBar(true);
  
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
  
    if (e.target.type !== "file" || !csvFileCheck(e.target.files[0].name)) {
      return;
    }
  
    const data = new FormData();
    data.append("file", value);
  
    const result = service.ImportCSVData(data);
    result.then((response) => {
      setShowLoadingBar(false);
  
      if (response.status !== 200) {
        if (response.status === 405) {
          showNotifyWindow("show", "error", i18n.t("errorMessages.file_mismatch"));
        } else {
          showNotifyWindow("show", "error", i18n.t("errorMessages.import_failed"));
        }
  
        return;
      }
  
      if (!response.data.message) {
        showNotifyWindow("show", "error", i18n.t("errorMessages.import_failed"));
        return;
      }
  
      switch (response.data.message) {
        case "no_file_records":
          showNotifyWindow("show", "error", i18n.t("errorMessages.empty_file"));
          break;
        case "invalid_headings":
          showNotifyWindow("show", "error", i18n.t("errorMessages.invalid_headings"));
          break;
        case "data_imported":
          if (response.data.existing_name && response.data.existing_name.length > 0) {
            showNotifyWindow("show", "warning", i18n.t("errorMessages.record_not_imported") + "\n" + response.data.existing_name.join(",\n"));
          } else {
            showNotifyWindow("show", "success", i18n.t("validations.success.import_success"));
            getICD10List(DEFAULT_PAGING_SIZE, 1);
          }
          break;
        default:
          showNotifyWindow("show", "error", i18n.t("errorMessages.import_failed"));
          break;
      }
    });
  
    document.getElementById("file").value = "";
  }
  

  function addNew() {
    setShowMasterICD10Modal(true);
    setMasterActivePage(1);
    setMasterStartIndex(0);
    setMasterEndIndex(PAGING_END_INDEX);
    getMasterICD10List(DEFAULT_PAGING_SIZE, 1);
  }

  function resetMatserICD10Data() {
    setSelectedIds([]);
    setSearchICD10("");
  }


  function onHandleCheckBoxChange(e, id) {
    if (e.target.checked) {
      if (selectedIds.indexOf(id) < 0) {
        setSelectedIds([...selectedIds, id]);
      }
    } else {
      const index = selectedIds.indexOf(id);
      if (index > -1) {
        let newIds = selectedIds.filter((item) => Number(item) != Number(id));
        setSelectedIds(newIds);
      }
    }
  }

  function onSaveFormData() {
    if (selectedIds.length == 0) return;
    setShowLoadingBar(true);
    const data = {
      practice_pk: practicePK,
      icd10_pks: selectedIds,
    };
    const result = service.AddICD10sToPractice(data);
    result.then(() => {
      setActivePage(1);
      setStartIndex(0);
      setEndIndex(PAGING_END_INDEX);
      getICD10List(DEFAULT_PAGING_SIZE, 1);
      setShowMasterICD10Modal(false);
      showNotifyWindow("show", "success", ADD_SUCCESS);
      setSelectedIds([]);
      setSearchICD10('');
    }).catch(() => {
      showNotifyWindow('show', 'error', 'Unexpected Error');
    }).finally(() => {
      setShowLoadingBar(false);
    });
  }
 
  function onHandleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let trimmedValue = "";
    trimmedValue = value;
    if (name === "searchICD10") {
      setSearchICD10(trimmedValue);
      setMasterActivePage(1);
      getMasterICD10List(DEFAULT_PAGING_SIZE, 1, trimmedValue, true);
    } else if (name === "searchValue") setSearchValue(trimmedValue);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearchICDCodes(e);
    }
  };

  function onSearchICDCodes(e, fieldName) {
    e.preventDefault();
    setActivePage(1)
    setStartIndex(0);
    setEndIndex(PAGING_END_INDEX);
    if (!fieldName) {
      // if not field name then call from ,search button click
      setInitialOrdering(true);
      setOrderType("");
    }
    getICD10List(DEFAULT_PAGING_SIZE, 1 , fieldName, true);
  }

  function onSortingICDCodes(e, fieldName) {
    e.preventDefault();
    setStartIndex(0);
    setEndIndex(PAGING_END_INDEX);
    if (!fieldName) {
      // if not field name then call from ,search button click
      setInitialOrdering(true);
      setOrderType("");
    }
    getICD10List(DEFAULT_PAGING_SIZE, activePage , fieldName, true);
  }

  function onHandleChangeactive(e) {
    setActiveType(e.target.value);
}

  function dropDownFunction(id, name) {
    if (!permission) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      return;
    }

    if (name.toLowerCase() == "edit") {
      onEditICD10("", Number(id));
    } else if (name.toLowerCase() == "delete") {
      onDeleteICD10("", Number(id));
    }
  }

  function onEditICD10(e, id) {
    setShowModalWindow(true);
    setHeader(i18n.t("dictionariesPages.dictICD10.editHeader"));
    setEditICD10Id(id);
  }


  function resetForm() {
  }


  function onDeleteICD10(e, id) {
    ICD10List.map((item) => {
      if (item.id == parseInt(id)) {
        setDeleteICD10(item.name);
        setDeleteICD10Id(item.id);
        setShowDeleteModalWindow(true);
      }
    });
  }

  function onDeleteAction() {
    setShowLoadingBar(true);
    const path = isAdminModule === "true" ? "super-admin" : "practice";
    const result = service.DeleteICDFromPractice(deleteICD10Id, path);
    result.then(() => {
      setShowLoadingBar(false);
      setShowDeleteModalWindow(false);
      setDeleteICD10("");
      setDeleteICD10Id("");
      showNotifyWindow("show", "success", DELETE_SUCCESS);
      if (totalPage <= activePage) {
        getICD10List(DEFAULT_PAGING_SIZE, "last");
      } else {
        getICD10List(DEFAULT_PAGING_SIZE, activePage);
      }
    });
  }

  function onDeleteHide() {
    setDeleteICD10("");
    setDeleteICD10Id("");
    setShowDeleteModalWindow(false);
  }

  const tableSorting = (e) => {
    setInitialOrdering(false);
    let sortingField = "name";
    setOrderingField(sortingField);
    if (!orderType) setOrderType("-");
    else setOrderType("");
    onSortingICDCodes(e, sortingField);
  };

  function backToCodes() {
    props.history.push(ROUTE_CODE_MANAGEMENT);
  }
  function onClickGridCheckBox(e, clickedFrom, id) {
    if (clickedFrom == "data") {
      onHandleCheckBoxChange(e, id);
    }
    // else if(clickedFrom=='header') {
    //     handleAllCheckedOnChange(e);
    // }
  }

  const onAddManually = () => {
    setShowAddManuallyWindow(true);
    setHeader("Add New");
  };
  const onFormSubmit = (message) => {
    showNotifyWindow("show", "success", message);
    getICD10List(DEFAULT_PAGING_SIZE, activePage);
  };
  const OnExportMenuItemClicked = (e, type) => {
    // eslint-disable-next-line no-undef
    const fileDownload = require("js-file-download");
    setShowLoadingBar(true);
    let status = activeType === 1 ? 'all' : activeType === '2' ? 'active' : 'inactive';
    service
      .ExportICD(type, practicePK,status)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          fileDownload(result.data, result.headers["content-disposition"]);
          setShowLoadingBar(false);
          showNotifyWindow("show", "success", "Export Success");
        } else {
          showNotifyWindow('show', 'error', 'Unexpected Error');
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", "Unexpected Error");
      });
  };

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-md-12">
        <div className="box box-content-white">
          <div className="row">
            <div className="col pl-4 mb-1">
              <div className={"dataTables_filter"}>
                {isAdminModule === "true" ? (
                  <div className="link dictionaries-back" onClick={backToDictionaryPage} >
                    <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /> 
                    </div>
                  ) : (
                    <div className="link dictionaries-back" onClick={backToCodes}
                    >
                     <BackArrowWithLabel label={i18n.t("codes.codes")} /> 
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="box-content">
            <div className="common-search-wrapper-style">
              <div className="input-content-box">
                <div className="form-group padding-top15 relative">
                  <TextInput
                    type="text"
                    name="searchValue"
                    value={searchValue}
                    onValueChange={onHandleChange}
                    label={i18n.t(
                      "dictionariesPages.dictICD10.icdOrDescription"
                    )}
                    onKeyDown={handleKeyDown}
                  />
                  {(searchValue.length !== 0) && <IconButton
                    className="templateIconBtn_close"
                    onClick={() => {
                      setSearchValue('');
                      setActivePage(1)
                      setSearchValueClose(searchValueClose + 1)
                    }} >
                    <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                  </IconButton>
                  }
                </div>
              </div>
              <div className="input-content-box padding-top15">
                <div className="margin-top15">
                  <button
                    type="button"
                    onClick={(e) => onSearchICDCodes(e)}
                    className="btn btn-primary-blue margin-top10"
                  >
                    {i18n.t("dictionariesPages.dictICD10.search")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box box-content-white margin-top10">
          <div className="">
            <div className="table-responsive">
              {isAdminModule === "true" && (
                <div className="alignRight margin-bottom10">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip
                        id="download-tooltip"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        {i18n.t("commons.importCSV")}
                      </Tooltip>
                    }
                  >
                    <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                  </OverlayTrigger>
                  <input
                    name="csvFile"
                    type="file"
                    accept=".csv"
                    id="file"
                    onChange={onHandleCSVChange}
                    style={{ display: "none" }}
                  />
                </div>
              )}
              {isAdminModule !== "true" && (
                <div>
                  {permission &&
                    <>
                    <div className="alignRight margin-bottom10 margin-top18">
                      <button
                        type="button"
                        className="btn btn-primary-blue"
                        onClick={addNew}
                      >
                        {i18n.t("buttons.addFromMaster")}
                      </button>
                    </div>
                    <div className="alignRight margin-bottom10 margin-top18 mr-4">
                      <button
                        type="button"
                        className="btn btn-primary-blue"
                        onClick={onAddManually}
                      >
                        {i18n.t("buttons.addManually")}
                      </button>
                    </div>
                    </>
                  }
                  <div className="alignRight margin-bottom10 margin-top18 mr-4">
                    <ExportIconButton
                      onExportCsv={(e) => OnExportMenuItemClicked(e, "csv")}
                      onExportXls={(e) => OnExportMenuItemClicked(e, "xls")}
                    />
                  </div>
                  <div  style={{marginRight:20,float:'right'}}>
                            <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChangeactive} label={i18n.t('commons.status')} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
                        </div>
                </div>
              )}
              <div style={{ overflowX: "auto", width: "100%"}}>
              <Table
                tableObject={
                  isAdminModule === "true" ? ICD10AdminTable : ICD10Table
                }
                dropDownFunction={dropDownFunction}
                sortingFunction={tableSorting}
                list={masterICD10List}
              />
              </div>
              <Pagination
                totalPage={totalPage}
                activePage={activePage}
                startIndex={startIndex}
                endIndex={endIndex}
                onPagePrevious={onPagePrevious}
                onPageUp={onPageUp}
                onPageNext={onPageNext}
              />
              <div className="height-75">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      {/* Add new From Existing */}
      <CustomizedDialogs
        showModal={showMasterICD10Modal}
        onSaveFormData={onSaveFormData}
        header={i18n.t("dictionariesPages.dictICD10.addHeader")}
        type="formSubmit"
        resetForm={resetMatserICD10Data}
        setShowModalWindow={setShowMasterICD10Modal}
        addNoOverflowXClass={true}
      >
        <div className="row">
          <div className="col-6">
            <div className="form-group padding-top20">
              <TextInput
                name="searchICD10"
                value={searchICD10}
                onValueChange={onHandleChange}
                label={i18n.t("dictionariesPages.dictICD10.icd10")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table
              tableObject={ICD10AddTableObject}
              onClickGridCheckBox={onClickGridCheckBox}
            />
            <Pagination
              totalPage={masterTotalPage}
              activePage={masterActivePage}
              startIndex={masterStartIndex}
              endIndex={masterEndIndex}
              onPagePrevious={onMasterPagePrevious}
              onPageUp={onMasterPageUp}
              onPageNext={onMasterPageNext}
            />
            {masterTotalPage <= 1 && (
              <div className="clear-b-scroll-sector">&nbsp;</div>
            )}
          </div>
        </div>
      </CustomizedDialogs>
      <CustomizedSmallDialogs
        showModal={showDeleteModalWindow}
        header={i18n.t("commons.confirmDelete")}
        type="delete"
        deleteItem={onDeleteAction}
        resetForm={resetForm}
        onHide={onDeleteHide}
        setShowModalWindow={setShowDeleteModalWindow}
      >
        {i18n.t("dictionariesPages.dictICD10.confirmDelete") +
          " '" +
          deleteICD10 +
          "'?"}
      </CustomizedSmallDialogs>
      <CustomizedSmallDialogs
        showModal={showModalWindow}
        type="save"
        header={header}
        setShowModalWindow={setShowModalWindow}
        resetForm={resetForm}
        chkInactive={true}
        setInactive={(value) => setEditActiveStatus(value)}
        inactive={editActiveStatus}
        pk={editICD10Id}
      >
        <IDC10Edit
          activeStatus={editActiveStatus}
          setActiveStatus={(val) => setEditActiveStatus(val)}
          isEdit={true}
          pk={editICD10Id}
          closeWindow={setShowModalWindow}
          onFormSubmit={onFormSubmit}
        />
      </CustomizedSmallDialogs>
      {/* Add Manually Dialog */}
      <CustomizedSmallDialogs
        chkInactive={true}
        setInactive={(value) => setAddManualActiveStatus(value)}
        inactive={addManualActiveStatus}
        showModal={showAddManuallyWindow}
        type="save"
        header={header}
        setShowModalWindow={setShowAddManuallyWindow}
        resetForm={resetForm}
      >
        <IDC10Edit
          activeStatus={addManualActiveStatus}
          setActiveStatus={(val) => setAddManualActiveStatus(val)}
          isEdit={false}
          closeWindow={setShowAddManuallyWindow}
          onFormSubmit={onFormSubmit}
        />
      </CustomizedSmallDialogs>
    </React.Fragment>
  );
};
export default Icd10;
